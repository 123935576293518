<template>
<div>
<section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Pregled artikala</h1>
          </div>
          <!-- <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Buttons</li>
            </ol>
          </div> -->
        </div>
      </div><!-- /.container-fluid -->
    </section>
<section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4">            
            <b-button-group >
              <b-button variant="primary" 
              v-on:click="$router.push({path: '/artikl', name: 'artikl.edit', params: { id: -1}});"
              >
                <i class="nav-icon fas fa-plus"></i>
                Novi artikl</b-button>
            </b-button-group>
            &nbsp;
            <div class="btn-group">
              <b-button-group>
                <b-dropdown v-if="this.selected.length == 1" 
                :disabled="this.selected.length == 0" 
                v-on:click="$router.push({path: '/artikl', name: 'artikl.edit', params: { id: selected[0].ArtiklID}});"
                right split text="Izmjena">

                <b-dropdown-item v-on:click="$router.push({path: '/artikl/kartica', name: 'artikl.kartica', params: { id: selected[0].ArtiklID}});">Kartica artikla</b-dropdown-item>

                  <!-- <b-dropdown-item>
                    <router-link :to="{ name: 'faktura.edit', params: { id: selected[0].FakturaID }}">Izmjena</router-link>
                  </b-dropdown-item> -->
                  <!--<b-dropdown-item v-on:click="email">E-mail</b-dropdown-item>
                  
                  <b-dropdown-item>Uplata</b-dropdown-item>
                  
                  <b-dropdown-divider></b-dropdown-divider>
                  
                  <b-dropdown-item v-on:click="copy">Kopiraj</b-dropdown-item>
                  <b-dropdown-item v-on:click="copyPonuda">Kopiraj u ponudu</b-dropdown-item>

                  <b-dropdown-divider></b-dropdown-divider>
                  
                  <b-dropdown-item>Briši</b-dropdown-item>
                  <b-dropdown-item>Storniraj</b-dropdown-item>
 -->
                </b-dropdown>
              </b-button-group >

            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">  
          &nbsp;
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <b-table hover selectable :items="items_filtered" :fields="fields" ref="selectableTable" 
              primary-key="ArtiklID"
              @row-selected="onRowSelected"
              :per-page="perPage"            
              :sort-by.sync="columnName"
              :sort-desc.sync="descendingSort"
              :no-sort-reset="true"
              :current-page="currentPage"
              >
              
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true"><i class="fas fa-check"></i></span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;&nbsp;</span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
                <!-- <template #cell(FakturaID)="data">
                  <b class="text-info">{{ data.value }}</b>, <b>{{ data.value }}</b>
                </template> -->
                <template #cell()="data">
                  {{ data.value }}
                </template>

                <template #cell(Usluga)="data">
                <template v-if="data.value">
                  <i class="far fa-check-square"></i>
                </template>
                <template v-else>
                  <i class="far fa-square"></i>
                </template>
                </template>
              </b-table>
            </div>
            <div class="card-footer">
                <!-- <b-pagination
                  v-model="currentPage"
                  :value="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="selectableTable"
                ></b-pagination> -->
                <b-pagination-nav v-model="currentPage" :link-gen="linkGen" :number-of-pages="pageCount" use-router></b-pagination-nav>               
              </div>
          </div>
        </div>
      </div>
    </div>
    
</section>
</div>
</template>

<script>
import datasource from "../_services/backend";
import store from "../libs/store";
import * as utils from "../helpers/index";

const artikli = datasource.get("artikl");

var unsubscribe;  //refrenca na firehose mutacija na storage objektu, za unsubscribe 

export default {
  name: "ArtiklBrowse",
  /* created služi kao onInit */
  created() {
    this.getAll();

    utils.EventBus.$on('triggersearch', this.searchHandler);
    utils.EventBus.$emit('showsearch')
  },
  mounted() {
    var tt = this;  //unutar handlera this ne postoji pa ga moraš spremiti vani
    unsubscribe = store.subscribe((mutation) => { //ovo je firehose svih mutacija na storage objektu, pa ono, pazi 
      if (mutation.type == 'updatePoslovnaGodina') {
        
        if (tt.$route.query.page) {
          tt.currentPage = 1;
          tt.$router
          .push({ query: { ...tt.$route.query, page: 1 } })
          .catch(() => {});
        }

        tt.getAll();
      }        
    });    

  },
  beforeRouteLeave(to,from,next) {
    unsubscribe();
    next();
  },
  data() {
    return {
      query: '',
      columnName: 'Broj',
      descendingSort: true,
      perPage: 10,
      currentPage: this.$route.query.page || 1,
      fields: [
        {key: 'selected', label: ''},
        {key: 'Sifra', label: 'Šifra', sortable: true},
        {key: 'Naziv', label: 'Naziv', sortable: true, class: 'nazivCol'},
        {key: 'Grupa', label: 'Grupa', sortable: true},
        {key: 'Cijena', label: 'Cijena', formatter: 'currencyFormatter', sortable: true},
        {key: 'CijenaValuta', label: 'Cijena v.', formatter: 'currencyFormatter2', sortable: true},
        {key: 'JM', label: 'JM'},
        {key: 'Porez', label: 'Porez'},
        {key: 'Usluga', label: 'Usluga'},
        {key: 'Marža', label: 'Marža'},
      ],
      items: [],
      selected: []
    };
  },
  computed: {
    items_filtered() {
      const q = this.query.toLowerCase(); 

      if (q == '') {
        return this.items;  
      }

      var bla = [];
      this.items.forEach(function(s){  
        if (s.Naziv.toLowerCase().includes(q) || (s.Grupa != null && s.Grupa.toLowerCase().includes(q)))
          bla.push(s);
      });

      //this.$set(this, 'items_filtered', bla);
      return bla;
    },     
    rows() {
      return this.items_filtered.length
    },
    pageCount()  {
      if (this.items_filtered.length == 0)
        return 1;
      else
        return Math.ceil(this.items_filtered.length / 10);
    },
    //  currentPage:{
    //   get() {
    //     console.log("currentPage get" + this.$route.query.page || 1);
    //     return this.$route.query.page || 1;
    //   },
    //   set() {
    //     if (!this.handlePageChange) return; 
    //   }       
      // get () {
      //   console.log("currentPage GET");
      //   var b = this.$store.state.selectedPageFaktura;
      //   console.log(b)
      //   return b;
      // },
      // set (value) {
      //   console.log("currentPage SET " + value);
      //   this.$store.commit('selectedPageFaktura', value);
      // }
    //},
  },
  methods: {
    kartica() {

    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    currencyFormatter: function (value, x, item) {      
      if (item['ValutaNaziv'])
        return "-";
      else
        return utils.currencyFormatter(value, item['ValutaNaziv']);

    },    
    currencyFormatter2: function (value, x, item) {      
      if (item['ValutaNaziv'])
        return utils.currencyFormatter2(value, item['ValutaNaziv']);
      else
        return "-";
      
    },
    getAll: async function() {
      const { data } = await artikli.getAll();
      this.$set(this, 'items', data);
    },
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    searchHandler(query) {
      this.currentPage = 1;
      this.query = query;
    }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.nazivCol {
  max-width: 300px;
}
</style>

