<template>
    <div>
        <!-- <nav>
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/logout">Logout</router-link></li>
            </ul>
        </nav>
        <div>
            <router-view />
        </div> -->

<div class="wrapper">
 <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button" data-enable-remember="true"><i class="fas fa-bars"></i></a>
      </li>
      <!-- <li class="nav-item d-none d-sm-inline-block">
        <a href="index3.html" class="nav-link">Home</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link">Contact</a>
      </li> -->

      <li class="nav-item">
        <b-form-select v-model="poslovnagodina" :options="this.$store.state.details.godine" ></b-form-select>
      </li>
    </ul>

    <!-- SEARCH FORM -->
    <div class="form-inline ml-3">
      <div class="input-group input-group-sm">
        <b-form-input v-if="filterVisible" class="form-control form-control-navbar" type="search" placeholder="filtriraj" 
        aria-label="filtriraj" v-model="searchInput" @input="triggerSearch"></b-form-input>
        <!-- <div class="input-group-append">
          <button class="btn btn-navbar" type="submit">
            <i class="fas fa-search"></i>
          </button>
        </div> -->
      </div>
      <div v-if="sakrijPlacenoVisible" class="input-group input-group-sm" style="margin-left:20px; ">
        <b-form-checkbox v-model="sakrijPlaceno_value" @input="sakrijPlaceno" value="true" unchecked-value="false" id="sakrijPlaceno">
          Sakrij plaćeno
        </b-form-checkbox>
      </div>
    </div>


   </nav>

   <!-- Main Sidebar Container -->
   <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <!-- <a href="index3.html" class="brand-link"> -->
      <!-- <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8"> -->
      <!-- <span class="brand-text font-weight-light">InSales</span> -->
    <!-- </a> -->

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <!-- <div class="image">
          <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
        </div> -->
        <div class="info" style="color:#d0d4db;">
          {{ this.$store.state.details.name }}<br>
          {{ this.$store.state.details.tvrtka }}
        </div>
      </div>

      <!-- SidebarSearch Form -->
      <!-- <div class="form-inline">
        <div class="input-group" data-widget="sidebar-search">
          <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
          <div class="input-group-append">
            <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div> -->

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <li class="nav-item">
            <router-link to="/dashboard" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <!-- <font-awesome-icon icon="tachometer-alt"></font-awesome-icon> -->
              <p>
                Dashboard
                <!-- <span class="badge badge-info right">2</span> -->
              </p>
            </router-link>
          </li>

          <li class="nav-header">DOKUMENTI</li>
          <li class="nav-item">
            <router-link to="/faktura" class="nav-link">
              <i class="nav-icon fas fa-file-invoice"></i>
              <p>
                Faktura
                <!-- <span class="badge badge-info right">2</span> -->
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/ponuda" class="nav-link">
              <i class="nav-icon far fa-file-alt"></i>
              <p>
                Ponuda
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/ulazniracun" class="nav-link">
              <i class="nav-icon fas fa-columns"></i>
              <p>
                Ulazni račun
              </p>
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-truck"></i>
              <p>
                Otpremnica
              </p>
            </a>
          </li> -->
          <li class="nav-item">
            <router-link to="/invoice" class="nav-link">
              <i class="nav-icon fas fa-globe-americas"></i>
              <p>
                Invoice
              </p>
            </router-link>
          </li>

          <li class="nav-header">ENTITETI</li>
          <li class="nav-item">
            <router-link to="/partner" class="nav-link">
              <i class="nav-icon far fa-address-card"></i>
              <p>
                Partner
              </p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/artikl" class="nav-link">
              <i class="nav-icon fas fa-cubes"></i>
              <p>
                Artikl
              </p>
            </router-link>
          </li>

          <!-- <li class="nav-header">OSTALO</li>
          <li class="nav-item">
            <router-link to="/fakturarecurrent" class="nav-link">
              <i class="nav-icon far fa-clock"></i>
              <p>
                Raspored kopiranja
              </p>
            </router-link>
          </li> -->

        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper col-lg-9 col-md-12 col-sm-12" style="max-width:1080px; margin-bottom: 70px; padding: 0.5rem">
      <router-view></router-view>
    </div>
    
    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->

  </div>

    <!-- Main Footer -->
     <footer class="main-footer fixed-bottom" >
        Powered by <b>špek</b>
    </footer>

    <vue-confirm-dialog></vue-confirm-dialog>
 </div>

</template>

<script>
import * as utils from "../helpers/index";
import {debounce} from 'lodash';

  export default {
    name: 'HomeLayout',
    created() {
      utils.EventBus.$on('clearsearch', () => {this.searchInput = ''});
      utils.EventBus.$on('showsearch', () => {this.filterVisible = true});
      utils.EventBus.$on('hidesearch', () => { this.filterVisible = false });

      utils.EventBus.$on('showSakrijPlaceno', () => {this.sakrijPlacenoVisible = true});
      utils.EventBus.$on('hideSakrijPlaceno', () => { this.sakrijPlacenoVisible = false });

    },
    data: function() {
      return {
        sakrijPlacenoVisible: false,
        filterVisible: false,
        searchInput: '',
        bla: 1
      }
    },
    methods: {
      poslovnaGodinaRefreshHook: function() {

      },
      // updatePoslovnaGodina(e) {
      //   this.$store.commit('poslovnagodina', e.target.value)
      // },
      triggerSearch: debounce(function() {     
        utils.EventBus.$emit('triggersearch', this.searchInput);
      }, 500),
      sakrijPlaceno() {
        utils.EventBus.$emit('sakrijPlaceno', this.sakrijPlaceno_value);
      }         
    },
    computed: {
      poslovnagodina: {
        get () {
          return this.$store.state.poslovnagodina;
        },
        set(value) {
          this.$store.commit('updatePoslovnaGodina', value);
        }
      },
      sakrijPlaceno_value: {
        get() {
          console.log("store get sakrijplaceno " + this.$store.state.sakrijplaceno)
          return this.$store.state.sakrijplaceno;
        },
        set(value) {          
          console.log("store commit updateSakrijPlaceno " + value)
          this.$store.commit('updateSakrijPlaceno', value);
        }
      }}
    
  }
</script>

<style>

.small-tablecol *{
  max-width: 100px !important;
}
.previewitem {
  display: block;
  color: #c2c7d0;
}
.form-control {
  display: unset !important;
}

.table-hover tbody tr:hover,
.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th{
    background-color: #fcfcfc !important;
}

.stavka-naziv {
  cursor: pointer;
}
.stavka-dodatniopis {
  display:block;
  cursor: pointer;
}

.custom-control-label {
  font-weight: unset !important;
}

</style>
